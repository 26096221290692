import * as d3 from 'd3';
import $ from 'jquery';
import _ from 'lodash';

import { isNil } from '../helpers.js';

const MINIMUM_SEARCH_LENGTH = 4;
const SEARCH_TIMEOUT = 500;

let timer;

export function init() {
	if ($('#search-section').length === 0) {
		return;
	}

	$('#search-input').on('input', onSearchInput);
	$('#search-input')[0].focus();

	// Events
	//
	window.onpopstate = function (event) {
		const { query } = event.state;

		if (!isNil(query)) {
			executeSearch({ query });
		}
	};

	// Get the fragment, and execute the search query.
	//
	const query = decodeURIComponent(window.location.hash.substr(1));

	if (!isNil(query)) {
		executeSearch({ query });
		$('#search-input').val(decodeURIComponent(query));
	}
}

async function onSearchInput() {
	clearTimeout(timer);
	timer = setTimeout(search, SEARCH_TIMEOUT);
}

async function search() {
	const query = $('#search-input').val().trim();

	if (query.length < MINIMUM_SEARCH_LENGTH) {
		return;
	}

	history.pushState({ query }, query, `#${query}`);

	executeSearch({ query });
}

async function executeSearch({ query }) {
	const path = `/api/4.0/search?q=${encodeURIComponent(query)}`;
	const response = await fetch(path, {
		headers: { 'Content-Type': 'application/json' },
		method: 'GET',
	});

	if (response.status !== 200) {
		console.log(response);
		return;
	}

	const text = await response.text();
	const result = JSON.parse(text);

	renderResults(result);
}

function clearResults() {
	d3.select('#results').selectAll('*').remove();
}

function renderResults({ players, teams }) {
	console.log(teams);

	clearResults();

	if (!_.isEmpty(players)) {
		d3.select('#results')
			.append('div')
			.attr('class', 'section-header')
			.append('label')
			.text('Players');

		d3.select('#results')
			.append('ul')
			.attr('class', 'content-list')
			.selectAll('li')
			.data(players)
			.enter()
			.append('li')
			.append('a')
			.attr('class', 'content-block')
			.attr('href', (player) => `/players/${player.id}`)
			.append('div')
			.attr('class', 'content-table')
			.append('div')
			.attr('class', 'content-cell')
			.append('div')
			.attr('class', 'bold')
			.text((player) => player.name);
	}

	if (!_.isEmpty(teams)) {
		d3.select('#results')
			.append('div')
			.attr('class', 'section-header')
			.append('label')
			.text('Groups');

		const contentTable = d3
			.select('#results')
			.append('ul')
			.attr('class', 'content-list')
			.selectAll('li')
			.data(teams)
			.enter()
			.append('li')
			.append('a')
			.attr('href', (team) => `/teams/${team.id}`)
			.attr('class', 'content-block')
			.append('div')
			.attr('class', 'content-table');

		const contentCell = contentTable.append('div').attr('class', 'content-cell');

		contentCell
			.append('div')
			.attr('class', 'yellow bold')
			.text((team) => team.name);

		contentCell.append('div').text((team) => _.get(team, 'location.cityStateString'));

		contentTable
			.append('div')
			.attr('class', (team) => (team.members.length > 0 ? 'indicator-cell yellow-cell' : ''))
			.text((team) => (team.members.length > 0 ? `${team.members.length} P` : ''));
	}
}
