import $ from 'jquery';
import _ from 'lodash';

import { isNil } from '../helpers.js';

export function init() {
	if ($('#news-item-edit-section').length === 0) {
		return;
	}

	broadside.diff = {};

	// Text inputs
	//
	_.each(['description', 'source', 'title', 'url'], (prop) => {
		const $elem = $(`#${prop}`);
		$elem.on('input', () => {
			_.set(broadside.diff, prop, $elem.val());
		});
	});

	// Is Permanent
	//
	$('#is-permanent').on('change', () => {
		_.set(broadside.diff, 'isPermanent', $('#is-permanent').is(':checked'));
	});

	// File
	//
	$('#input-file').on('change', function () {
		const file = $(this)[0].files[0];
		const reader = new FileReader();

		reader.onload = (e) => $('#preview-image').attr('src', e.target.result);
		reader.readAsDataURL(file);

		$('#header-image-container').show();
	});

	// Image preview
	//
	const src = $('#preview-image').attr('src');

	if (_.isEmpty(src)) {
		$('#header-image-container').hide();
	}

	// Context menu
	//
	$('#save-news-item').on('click', onSaveNewsItemClick);
}

async function onSaveNewsItemClick() {
	await saveNewsItem();
}

async function saveNewsItem() {
	const data = new FormData();

	const description = _.get(broadside, 'diff.description');
	if (!_.isEmpty(description)) {
		data.append('description', description);
	}

	const source = _.get(broadside, 'diff.source');
	if (!_.isEmpty(source)) {
		data.append('source', source);
	}

	const title = _.get(broadside, 'diff.title');
	if (!_.isEmpty(title)) {
		data.append('title', title);
	}

	const url = _.get(broadside, 'diff.url');
	if (!_.isEmpty(url)) {
		data.append('url', url);
	}

	const isPermanent = _.get(broadside, 'diff.isPermanent');
	if (!isNil(isPermanent)) {
		data.append('isPermanent', isPermanent);
	}

	const file = $('#input-file')[0].files[0];
	if (!isNil(file)) {
		data.append('file', file);
	}

	const newsItemId = _.get(broadside, 'newsItem.id');
	const path = newsItemId ? `/api/4.0/news/${newsItemId}` : '/api/4.0/news';
	const response = await fetch(path, {
		body: data,
		method: 'POST',
	});

	if (response.status !== 200) {
		console.log(response);
		return;
	}

	location.href = newsItemId ? `/news/${newsItemId}` : '/news';
}
