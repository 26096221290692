import $ from 'jquery';
import _ from 'lodash';

import { BROADSIDE_TOKEN_COOKIE } from '../constants.js';
import { getResultString } from '../helpers.js';

export function init() {
	if ($('#sign-up-section').length === 0) {
		return;
	}

	$('#submit').on('click', onSubmitClick);
}

async function onSubmitClick() {
	const name = $('#name').val().trim();

	if (_.isEmpty(name)) {
		$('#name').select();
		return;
	}

	const email = $('#email').val().trim();

	if (_.isEmpty(email)) {
		$('#email').select();
		return;
	}

	const password = $('#password').val();

	if (_.isEmpty(password)) {
		$('#password').select();
		return;
	}

	const confirmPassword = $('#confirmPassword').val().trim();

	if (_.isEmpty(confirmPassword)) {
		$('#confirmPassword').select();
		return;
	}

	const body = {
		confirmPassword,
		email,
		name,
		password,
	};

	const path = `/api/4.0/sign-up`;
	const response = await fetch(path, {
		body: JSON.stringify(body),
		headers: {
			'Content-Type': 'application/json',
			'X-Broadside-Token': BROADSIDE_TOKEN_COOKIE,
		},
		method: 'POST',
	});

	const text = await response.text();
	const { result } = JSON.parse(text);
	const resultString = getResultString(result);

	if (response.status !== 200) {
		$('#error-block').show();
		$('#error-block .content-cell').text(resultString);
		return;
	}

	location.href = '/';
}
