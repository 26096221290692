import $ from 'jquery';
import _ from 'lodash';

export function init() {
	if ($('#team-edit-section').length === 0) {
		return;
	}

	broadside.diff = {};

	// Text inputs
	//
	_.each(['city', 'country', 'postalCode', 'name', 'notes', 'state'], (prop) => {
		const $elem = $(`#${prop}`);
		$elem.on('input', () => {
			_.set(broadside.diff, prop, $elem.val());
		});
	});

	// Numeric inputs
	//
	_.each(['ageGroup', 'skillLevel'], (prop) => {
		const $elem = $(`#${prop}`);
		$elem.on('input', () => {
			_.set(broadside.diff, prop, parseInt($elem.val()), 10);
		});
	});

	// Header bar
	//
	$('#name').on('input', function () {
		$('#header-section .title-cell').text($(this).val());
	});

	// Context menu
	//
	$('#save-team').on('click', onSaveTeamClick);
}

async function onSaveTeamClick() {
	await saveTeam();
}

async function saveTeam() {
	const teamId = _.get(broadside, 'team.id');
	const path = teamId ? `/api/4.0/teams/${teamId}` : '/api/4.0/teams';
	const response = await fetch(path, {
		body: JSON.stringify(broadside.diff),
		headers: { 'Content-Type': 'application/json' },
		method: 'POST',
	});

	if (response.status !== 200) {
		console.log(response);
		return;
	}

	location.href = teamId ? `/teams/${teamId}` : '/teams';
}
