import $ from 'jquery';
import _ from 'lodash';

export function init() {
	if ($('#player-edit-image-section').length === 0) {
		return;
	}

	$('#upload-image').on('click', onUploadImageClick);
	$('#delete-image').on('click', onDeleteImageClick);

	const src = $('#preview-image').attr('src');

	if (_.isEmpty(src)) {
		$('#header-image-container').hide();
	}

	$('#input-file').on('change', function () {
		const file = $(this)[0].files[0];
		const reader = new FileReader();

		reader.onload = (e) => $('#preview-image').attr('src', e.target.result);
		reader.readAsDataURL(file);

		$('#header-image-container').show();
	});
}

async function onDeleteImageClick() {
	if (!confirm('Are you sure you want to delete your profile image?')) {
		return;
	}

	const response = await fetch('/api/4.0/player/image', {
		method: 'DELETE',
	});

	if (response.status !== 200) {
		console.log(response);
		return;
	}

	location.href = '/player';
}

async function onUploadImageClick() {
	const data = new FormData();
	const file = $('#input-file')[0].files[0];

	data.append('file', file);

	const response = await fetch('/api/4.0/player/image', {
		body: data,
		method: 'POST',
	});

	if (response.status !== 200) {
		console.log(response);
		return;
	}

	location.href = '/player';
}
