import $ from 'jquery';
import _ from 'lodash';
import moment from 'moment';

import {
	DATE_TIME_FORMAT_INPUT,
	GAME_DAY,
	GAME_FREQUENCY_EVERY_WEEK,
	GAME_RESTRICTION_MODE_BY_COUNT,
} from '../constants.js';
import { isNil, isValidId } from '../helpers.js';

export function init() {
	if ($('#game-edit-section').length === 0) {
		return;
	}

	broadside.diff = {};

	const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
	const existingTimezone = _.get(broadside, 'game.timezone');

	if (!_.isEmpty(currentTimezone) && currentTimezone != existingTimezone) {
		broadside.diff.timezone = currentTimezone;
	}

	// Init the new game controls
	//
	if (_.isEmpty(broadside.game)) {
		const startDateMoment = moment().add(1, 'hour').set({
			millisecond: 0,
			minute: 0,
			second: 0,
		});

		const startDateString = startDateMoment.format(DATE_TIME_FORMAT_INPUT);
		$('#startDate').val(startDateString);

		const utcStartDateString = startDateMoment.utc().toISOString();
		_.set(broadside, 'diff.startDate', utcStartDateString);
	}

	// Text inputs
	//
	_.each(['address', 'city', 'country', 'notes', 'postalCode', 'state', 'venue'], (prop) => {
		const $elem = $(`#${prop}`);
		$elem.on('input', () => {
			$(`#${prop} .empty-option`).remove();
			_.set(broadside, `diff.${prop}`, $elem.val());
		});
	});

	// Numeric inputs
	//
	_.each(
		['duration', 'frequency', 'restrictionMode', 'restrictionData', 'status', 'teamId'],
		(prop) => {
			const $elem = $(`#${prop}`);
			$elem.on('input', () => {
				$('#error-block').hide();
				$(`#${prop} .empty-option`).remove();
				_.set(broadside, `diff.${prop}`, parseInt($elem.val()), 10);
			});
		}
	);

	// Date inputs
	//
	_.each(['endDate', 'startDate'], (prop) => {
		const $elem = $(`#${prop}`);
		$elem.on('input', () => {
			$('#error-block').hide();
			const value = $elem.val();
			const utcDate = _.isEmpty(value) ? null : moment(value).utc().toISOString();
			_.set(broadside.diff, prop, utcDate);
		});
	});

	// Game days
	//
	_.each(['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'], (prop) => {
		const $elem = $(`#${prop}`);
		$elem.on('input', () => {
			_.set(broadside, 'diff.gameDays', getGameDays());
		});
	});

	// Restriction mode
	//
	const restrictionMode = _.get(broadside, 'recurringGame.restrictionMode', 0);
	const $restrictionData = $('#restrictionData');

	$restrictionData.prop('disabled', restrictionMode !== GAME_RESTRICTION_MODE_BY_COUNT);

	$('#restrictionMode').on('change', function () {
		const restrictionMode = parseInt($(this).val(), 10);
		$restrictionData.prop('disabled', restrictionMode !== GAME_RESTRICTION_MODE_BY_COUNT);
	});

	// Recurring game controls
	//
	$('#frequencyControls').toggle(!broadside.isEditing);
	$('#recurringGameControls').toggle(broadside.isEditingRecurringGame);

	$('#frequency').on('input', () => {
		const $elem = $('#frequency');
		const frequency = parseInt($elem.val(), 10);
		const isRecurring = frequency === GAME_FREQUENCY_EVERY_WEEK;

		$('#header-section .title-cell').text(isRecurring ? 'NEW GAME SERIES' : 'NEW GAME');
		$('#recurringGameControls').toggle(isRecurring);
	});

	// Context menu
	//
	$('#save-game').on('click', onSaveGameClick);
}

function getGameDays() {
	let i = 0;

	if ($('#sunday').is(':checked')) i += GAME_DAY.SUNDAY;

	if ($('#monday').is(':checked')) i += GAME_DAY.MONDAY;

	if ($('#tuesday').is(':checked')) i += GAME_DAY.TUESDAY;

	if ($('#wednesday').is(':checked')) i += GAME_DAY.WEDNESDAY;

	if ($('#thursday').is(':checked')) i += GAME_DAY.THURSDAY;

	if ($('#friday').is(':checked')) i += GAME_DAY.FRIDAY;

	if ($('#saturday').is(':checked')) i += GAME_DAY.SATURDAY;

	return i;
}

function validate() {
	const teamId = _.get(broadside, 'game.teamId') || _.get(broadside, 'diff.teamId');

	if (!isValidId(teamId)) {
		$('#error-block').show();
		$('#error-block .content-cell').text('Please choose a group.');
		return false;
	}

	const startDate = _.get(broadside, 'game.startDate') || _.get(broadside, 'diff.startDate');

	if (isNil(startDate)) {
		$('#error-block').show();
		$('#error-block .content-cell').text('Please set the start date and time.');
		return false;
	}

	return true;
}

async function onSaveGameClick() {
	if (!validate()) {
		return;
	}

	let path;

	if (broadside.isEditingRecurringGame) {
		const recurringGameId = _.get(broadside, 'game.id'); // game.id is the recurringGameId
		path = `/api/4.0/recurring-games/${recurringGameId}`;
	} else if (broadside.isEditing) {
		const gameId = _.get(broadside, 'game.id');
		path = `/api/4.0/games/${gameId}`;
	} else {
		const isRecurring = _.get(broadside, 'diff.frequency') === GAME_FREQUENCY_EVERY_WEEK;
		path = isRecurring ? '/api/4.0/recurring-games/' : '/api/4.0/games/';
	}

	const response = await fetch(path, {
		body: JSON.stringify(broadside.diff),
		headers: { 'Content-Type': 'application/json' },
		method: 'POST',
	});

	if (response.status !== 200) {
		console.log(response);
		return;
	}

	location.href = '/games';
}
