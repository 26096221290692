import $ from 'jquery';
import _ from 'lodash';

export function init() {
	if ($('#home-section').length === 0) {
		return;
	}

	$('#down-chevron-image').on('click', onDownChevronClick);
}

function onDownChevronClick() {
	const scrollTop = $('#app-section').offset().top;
	$('html, body').animate({ scrollTop }, 300);
}
