import $ from 'jquery';
import _ from 'lodash';

import { PLAYER_GAME_STATUS } from '../constants.js';
import { isNil, isValidId } from '../helpers.js';

export function init() {
	if ($('#game-section').length === 0) {
		return;
	}

	$('#set-status-in').on('click', () => setPlayerGameStatus({ status: PLAYER_GAME_STATUS.IN }));
	$('#set-status-out').on('click', () => setPlayerGameStatus({ status: PLAYER_GAME_STATUS.OUT }));
	$('#set-status-undecided').on('click', () =>
		setPlayerGameStatus({ status: PLAYER_GAME_STATUS.UNDECIDED })
	);
	$('#delete-game').on('click', onDeleteGameClick);
	$('#delete-recurring-game').on('click', onDeleteRecurringGameClick);
}

async function setPlayerGameStatus({ status }) {
	const gameId = _.get(broadside, 'game.id');

	if (isNil(gameId)) {
		return; // shouldn't happen
	}

	const path = `/api/4.0/games/${gameId}/player`;
	const response = await fetch(path, {
		body: JSON.stringify({ status }),
		headers: { 'Content-Type': 'application/json' },
		method: 'POST',
	});

	if (response.status !== 200) {
		console.log(response);
		return;
	}

	location.href = `/games/${gameId}`;
}

async function onDeleteGameClick() {
	if (!confirm('Are you sure you want to delete this game?')) {
		return;
	}

	await deleteGame();
}

async function deleteGame() {
	const gameId = _.get(broadside, 'game.id');

	if (!isValidId(gameId)) {
		return; // shouldn't happen
	}

	const path = `/api/4.0/games/${gameId}`;
	const response = await fetch(path, {
		method: 'DELETE',
	});

	if (response.status !== 200) {
		console.log(response);
		return;
	}

	location.href = '/games';
}

async function onDeleteRecurringGameClick() {
	if (!confirm('Are you sure you want to delete this recurring game series?')) {
		return;
	}

	await deleteRecurringGame();
}

async function deleteRecurringGame() {
	const recurringGameId = _.get(broadside, 'game.recurringGameId');

	if (!isValidId(recurringGameId)) {
		return;
	}

	const path = `/api/4.0/recurring-games/${recurringGameId}`;
	const response = await fetch(path, {
		method: 'DELETE',
	});

	if (response.status !== 200) {
		console.log(response);
		return;
	}

	location.href = '/games';
}
