import $ from 'jquery';
import _ from 'lodash';

import { getResultString } from '../helpers.js';

export function init() {
	if ($('#reset-password-section').length === 0) {
		return;
	}

	$('#submit').on('click', onSubmitClick);
}

async function onSubmitClick() {
	const code = $('#code').val();
	const password = $('#password').val();
	const confirmPassword = $('#confirmPassword').val();

	if (_.isEmpty(password)) {
		$('#password').select();
		return;
	}

	if (_.isEmpty(confirmPassword)) {
		$('#confirmPassword').select();
		return;
	}

	const path = '/api/4.0/reset-password';
	const response = await fetch(path, {
		body: JSON.stringify({ code, confirmPassword, password }),
		headers: { 'Content-Type': 'application/json' },
		method: 'POST',
	});

	const text = await response.text();
	const { result } = JSON.parse(text);
	const resultString = getResultString(result);

	if (response.status !== 200) {
		$('#error-block').show();
		$('#error-block .content-cell').text(resultString);
		return;
	}

	alert('Your password has been updated.');
	location.href = '/sign-in';
}
