import $ from 'jquery';
import _ from 'lodash';

import { isEmptyOrWhitespace } from './client-helpers.js';

export function init() {
	if ($('#push-edit-section').length === 0) {
		return;
	}

	broadside.diff = {};

	// Text inputs
	//
	_.each(['text', 'topicArn'], (prop) => {
		const $elem = $(`#${prop}`);
		$elem.on('input', () => {
			$(`#${prop} .empty-option`).remove();
			_.set(broadside.diff, prop, $elem.val());
		});
	});

	// Since we have a url select or a url textarea

	const $urlSelect = $('#url-select');
	const $urlTextarea = $('#url-textarea');

	$urlSelect.on('input', () => {
		$(`#url-select .empty-option`).remove();

		const value = $urlSelect.val();
		if (_.isEmpty(value)) {
			$urlTextarea.show();
		} else {
			$urlTextarea.hide();
			_.set(broadside.diff, 'url', value);
		}
	});

	$urlTextarea.on('input', () => {
		_.set(broadside.diff, 'url', $urlTextarea.val());
	});

	// Context menu
	//
	$('#send-push').on('click', onSendPushClick);
}

async function onSendPushClick() {
	const text = _.get(broadside, 'diff.text');

	if (isEmptyOrWhitespace(text)) {
		$('#text').focus();
		return;
	}

	const topicArn = _.get(broadside, 'diff.topicArn');

	if (isEmptyOrWhitespace(topicArn)) {
		alert('Please select a topic.');
		return;
	}

	const url = _.get(broadside, 'diff.url');

	if (isEmptyOrWhitespace(url)) {
		alert('Please select a deep link.');
		return;
	}

	await sendPush({
		text,
		topicArn,
		url,
	});
}

async function sendPush(params) {
	const path = '/api/4.0/push';
	const response = await fetch(path, {
		body: JSON.stringify(params),
		headers: { 'Content-Type': 'application/json' },
		method: 'POST',
	});

	if (response.status !== 200) {
		console.log(response);
		return;
	}

	location.href = '/';
}
