import $ from 'jquery';

import { DATE_REFRESH_INTERVAL, DEFAULT_ANIMATION_DURATION } from '../constants.js';
import { adjustUtcDatesToLocal, positionControls } from './client-helpers.js';
import { init as initAccountControls } from './account.js';
import { init as initAdEditControls } from './ad-edit.js';
import { init as initChangePasswordControls } from './change-password.js';
import { init as initForgotPasswordControls } from './forgot-password.js';
import { init as initGameControls } from './game.js';
import { init as initGameEditControls } from './game-edit.js';
import { init as initHomeControls } from './home.js';
import { init as initMapControls } from './map.js';
import { init as initNewsItemControls } from './news-item.js';
import { init as initNewsItemEditControls } from './news-item-edit.js';
import { init as initNotificationsControls } from './notifications.js';
import { init as initPlayerEditControls } from './player-edit.js';
import { init as initPlayerEditImageControls } from './player-edit-image.js';
import { init as initPushEditControls } from './push-edit.js';
import { init as initResetPassordControls } from './reset-password.js';
import { init as initSearchControls } from './search.js';
import { init as initSignInControls } from './sign-in.js';
import { init as initSignUpControls } from './sign-up.js';
import { init as initTeamControls } from './team.js';
import { init as initTeamEditControls } from './team-edit.js';
import { init as initTeamEditImageControls } from './team-edit-image.js';
import { init as initTeamMessagesControls } from './team-messages.js';

$(() => {
	initAccountControls();
	initAdEditControls();
	initChangePasswordControls();
	initContextMenu();
	initForgotPasswordControls();
	initGameControls();
	initGameEditControls();
	initHomeControls();
	initNavMenu();
	initNewsItemControls();
	initNewsItemEditControls();
	initNotificationsControls();
	initPlayerEditControls();
	initPlayerEditImageControls();
	initPushEditControls();
	initResetPassordControls();
	initSearchControls();
	initSignInControls();
	initSignUpControls();
	initTeamControls();
	initTeamEditControls();
	initTeamEditImageControls();
	initTeamMessagesControls();

	adjustUtcDatesToLocal();
	positionControls();

	$(window).on('resize', () => {
		positionControls();
		closeNavMenu();
	});

	if ($('#map-section').length > 0) {
		initMapControls();
	}

	setInterval(adjustUtcDatesToLocal, DATE_REFRESH_INTERVAL);
});

function initContextMenu() {
	$('#more-button').on('click', () => {
		$('.context-menu').slideToggle(DEFAULT_ANIMATION_DURATION);
	});
}

function initNavMenu() {
	$('#menu-button').on('click', () => {
		const currentlyOpen = $('nav').offset().left === 0;
		$('nav').toggleClass('opened', !currentlyOpen);
	});
}

function closeNavMenu() {
	$('nav').removeClass('opened');
}
