import $ from 'jquery';
import _ from 'lodash';

import { getResultString } from '../helpers.js';

export function init() {
	if ($('#change-password-section').length === 0) {
		return;
	}

	$('#submit').on('click', onSubmitClick);
}

async function onSubmitClick() {
	const oldPassword = $('#oldPassword').val().trim();

	if (_.isEmpty(oldPassword)) {
		$('#oldPassword').select();
		return;
	}

	const newPassword = $('#newPassword').val().trim();

	if (_.isEmpty(newPassword)) {
		$('#newPassword').select();
		return;
	}

	const confirmPassword = $('#confirmPassword').val().trim();

	if (_.isEmpty(confirmPassword)) {
		$('#confirmPassword').select();
		return;
	}

	const body = {
		confirmPassword,
		newPassword,
		oldPassword,
	};

	const path = `/api/4.0/change-password`;
	const response = await fetch(path, {
		body: JSON.stringify(body),
		headers: { 'Content-Type': 'application/json' },
		method: 'POST',
	});

	const text = await response.text();
	const { result } = JSON.parse(text);
	const resultString = getResultString(result);

	if (response.status !== 200) {
		$('#error-block').show();
		$('#error-block .content-cell').text(resultString);
		return;
	}

	location.href = '/settings';
}
