import _ from 'lodash';
import { escape } from 'html-escaper';

import {
	AD_INTERVAL,
	AGE_GROUPS,
	GAME_DURATION,
	GAME_STATUS,
	PLAYER_GAME_STATUS,
	RESULT,
	SKILL_LEVEL,
} from './constants.js';

export const addHours = (date, hours) => {
	const msPerHour = 3600000; // 1 hour = 60 mins * 60 secs * 1000 ms
	return new Date(date.getTime() + hours * msPerHour);
};

export const assertNotEmpty = (value, variableName, functionName) => {
	if (_.isEmpty(value)) {
		const details = functionName ? `${functionName} - ${variableName}` : variableName;
		console.log(`ERROR: ${details} is empty`);
		return false;
	}
	return true;
};

export const assertNotNil = (value, variableName, functionName) => {
	if (isNil(value)) {
		const details = functionName ? `${functionName} - ${variableName}` : variableName;
		console.log(`ERROR: ${details} is nil`);
		return false;
	}
	return true;
};

export const assertValidEmailAddress = (value, variableName, functionName) => {
	if (!isValidEmailAddress(value)) {
		const details = functionName ? `${functionName} - ${variableName}` : variableName;
		console.log(`ERROR: ${details} is an invalid email address`);
		return false;
	}
	return true;
};

export const createUuid = () => {
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
		var r = (Math.random() * 16) | 0,
			v = c === 'x' ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
};

export const getAd = ({ ads, index }) => {
	// Display a random ad after N other things (games, groups, etc)
	const adIndex = index % AD_INTERVAL;
	if (adIndex !== AD_INTERVAL - 1) {
		return;
	}

	const randomIndex = Math.floor(Math.random() * ads.length);
	return ads[randomIndex];
};

export const getAddressString = (location) => {
	const parts = [];
	if (location.address) parts.push(location.address);

	const cityParts = [];
	if (location.city) cityParts.push(`${location.city},`);
	if (location.state) cityParts.push(location.state);
	if (location.zipCode) cityParts.push(location.zipCode);

	const cityLine = cityParts.join(' ');
	if (cityLine.length > 0) parts.push(cityLine);

	return parts.join('\n');
};

export const getAgeGroupString = (value) => {
	const group = _.find(AGE_GROUPS, (item) => item.value === value);
	return group ? group.text : '';
};

export const getCityStateString = (location) => {
	const parts = [];
	const cityParts = [];
	if (location.city) cityParts.push(`${location.city},`);

	const isNotUS =
		!_.isEmpty(location.country) &&
		location.country !== 'US' &&
		location.country !== 'USA' &&
		location.country !== 'United States';

	if (isNotUS) {
		if (location.country) cityParts.push(location.country);
	} else {
		if (location.state) cityParts.push(location.state);
	}

	const cityLine = cityParts.join(' ');
	if (cityLine.length > 0) parts.push(cityLine);

	return parts.join('\n');
};

export const getCityStateZipString = (location) => {
	const parts = [];
	const cityParts = [];
	if (location.city) cityParts.push(`${location.city},`);
	if (location.state) cityParts.push(location.state);
	if (location.zipCode) cityParts.push(location.zipCode);

	const cityLine = cityParts.join(' ');
	if (cityLine.length > 0) parts.push(cityLine);

	return parts.join('\n');
};

export const getDurationString = (value) => {
	const duration = _.find(GAME_DURATION, (item) => item.value === value);
	return duration ? duration.text : '';
};

export const getGameRosterInCountString = ({ game, mobile }) => {
	const count = _.get(game, 'roster.in.length', 0);
	return count > 0 ? `${count} IN` : '';
};

export const getGameStatusString = (value) => {
	const status = _.find(GAME_STATUS, (item) => item.value === value);
	return status ? status.text : '';
};

export const getIsoNowString = () => {
	return new Date().toISOString();
};

export const getMapUrl = (location) => {
	if (isNil(location)) {
		return '';
	}

	const { addressString, latitude, longitude } = location;

	if (!_.isEmpty(addressString) && !isNil(latitude) && !isNil(longitude)) {
		const encodedAddressString = encodeURIComponent(addressString);
		return `https://www.google.com/maps/place/${encodedAddressString}/@${latitude},${longitude},14z`;
	} else if (!isNil(latitude) && !isNil(longitude)) {
		return `https://www.google.com/maps/@${latitude},${longitude},14z`;
	} else if (!_.isEmpty(addressString)) {
		const encodedAddressString = encodeURIComponent(addressString);
		return `https://www.google.com/maps/place/${encodedAddressString}`;
	}
};

export const getNbaDate = (date) => {
	const yyyy = date.getFullYear();
	let mm = date.getMonth() + 1; // Months are zero-based
	let dd = date.getDate();

	// Prefix single-digit month and day values with '0'
	if (mm < 10) mm = '0' + mm;
	if (dd < 10) dd = '0' + dd;

	return `${yyyy}-${mm}-${dd}`;
};

export const getNbaTeamAbbreviation = (teamId) => {
	const nbaTeamIdAbbreviationMap = {
		1610612737: 'ATL',
		1610612738: 'BOS',
		1610612739: 'CLE',
		1610612740: 'NOP',
		1610612741: 'CHI',
		1610612742: 'DAL',
		1610612743: 'DEN',
		1610612744: 'GSW',
		1610612745: 'HOU',
		1610612746: 'LAC',
		1610612747: 'LAL',
		1610612748: 'MIA',
		1610612749: 'MIL',
		1610612750: 'MIN',
		1610612751: 'BKN',
		1610612752: 'NYK',
		1610612753: 'ORL',
		1610612754: 'IND',
		1610612755: 'PHI',
		1610612756: 'PHX',
		1610612757: 'POR',
		1610612758: 'SAC',
		1610612759: 'SAS',
		1610612760: 'OKC',
		1610612761: 'TOR',
		1610612762: 'UTA',
		1610612763: 'MEM',
		1610612764: 'WAS',
		1610612765: 'DET',
		1610612766: 'CHA',
	};

	return nbaTeamIdAbbreviationMap[teamId];
};

export const getPlayerGameStatusString = (status) => {
	switch (status) {
		case PLAYER_GAME_STATUS.OUT:
			return 'Out';
		case PLAYER_GAME_STATUS.IN:
			return 'In';
		case PLAYER_GAME_STATUS.UNDECIDED:
			return 'Undecided';
		default:
			return;
	}
};

export const getResultString = (result) => {
	switch (result) {
		case RESULT.CHANGE_PASSWORD_NEW_PASSWORD_EMPTY:
			return 'The new password cannot be empty.';
		case RESULT.CHANGE_PASSWORD_OLD_PASSWORD_EMPTY:
			return 'The old password cannot be empty.';
		case RESULT.CHANGE_PASSWORD_OLD_PASSWORD_INVALID:
			return 'The old password is incorrect.';
		case RESULT.CHANGE_PASSWORD_PASSWORD_AND_CONFIRM_PASSWORD_DO_NOT_MATCH:
			return 'The new password and confirm passwords do not match.';
		case RESULT.CHANGE_PASSWORD_PLAYER_NOT_FOUND:
			return 'The player was not found.';
		case RESULT.RESET_PASSWORD_CODE_EXPIRED:
			return 'The password reset code has expired. Try sending yourself another reset password link.';
		case RESULT.RESET_PASSWORD_CODE_INVALID:
			return 'The password reset code is missing or invalid. Try sending yourself another reset password link.';
		case RESULT.RESET_PASSWORD_CODE_NOT_FOUND:
			return 'The password reset code is missing or invalid. Try sending yourself another reset password link.';
		case RESULT.RESET_PASSWORD_CODE_VALID:
			return 'The password reset code is valid.';
		case RESULT.RESET_PASSWORD_PASSWORD_AND_CONFIRM_PASSWORD_DO_NOT_MATCH:
			return 'The new password and confirm passwords do not match.';
		case RESULT.RESET_PASSWORD_PASSWORD_EMPTY:
			return 'The password cannot be empty';
		case RESULT.RESET_PASSWORD_PLAYER_NOT_FOUND:
			return 'A player was not found for this email address.';
		case RESULT.SIGN_IN_EMAIL_EMPTY:
			return 'The email address cannot be empty.';
		case RESULT.SIGN_IN_FAILED:
			return 'The password is incorrect.';
		case RESULT.SIGN_IN_PASSWORD_EMPTY:
			return 'The password cannot be empty';
		case RESULT.SIGN_IN_PLAYER_NOT_FOUND:
			return "InfiniteHoops 4.0 is an all-new rewrite of the code and a clean database. This is to let us build features faster on a modern stack.\n\n If you had an old account, you'll need to create a new one.";
		case RESULT.SIGN_UP_EMAIL_EMPTY:
			return 'The email address cannot be empty.';
		case RESULT.SIGN_UP_NAME_EMPTY:
			return 'The name cannot be empty';
		case RESULT.SIGN_UP_PASSWORD_AND_CONFIRM_PASSWORD_DO_NOT_MATCH:
			return 'The new password and confirm passwords do not match.';
		case RESULT.SIGN_UP_PASSWORD_EMPTY:
			return 'The password cannot be empty';
		case RESULT.SIGN_UP_PLAYER_ALREADY_EXISTS:
			return 'A player with email address already exists.';
		default:
			console.log(`Unknown result string: ${result}`);
			return 'Unknown error.';
	}
};

export const getSkillLevelString = (value) => {
	const level = _.find(SKILL_LEVEL, (item) => item.value === value);
	return level ? level.text : '';
};

export const getTeamMembersCountString = ({ team }) => {
	const count = _.get(team, 'members.length', 0);
	return count > 0 ? `${count} P` : '';
};

export const getUnixTimestamp = () => parseInt(new Date().getTime() / 1000, 10);

export const hasPlayerProfile = (player) => {
	if (isNil(player)) {
		return false;
	}

	return (
		!isNil(player.age) ||
		!_.isEmpty(player.alias) ||
		!_.isEmpty(player.favoritePlayers) ||
		!_.isEmpty(player.favoriteTeams) ||
		!_.isEmpty(player.location) ||
		!_.isEmpty(player.occupation) ||
		!_.isEmpty(player.website) ||
		!_.isEmpty(player.image)
	);
};

export const htmlEncode = (s) => (_.isEmpty(s) ? s : escape(s).replace(/\n/g, '<br>'));

export const isAuthenticated = (req) => !isNil(req.locals.player);

export const isNil = (value) => value == null || value == undefined;

export const isNilOrTBD = (value) => isNil(value) || value === 'TBD';

export const isValidEmailAddress = (email) =>
	!!String(email)
		.toLowerCase()
		.match(
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		);

export const isValidId = (id) => _.isNumber(id) && id > 0;

export const mapHeadersToRow = (headers, row) => {
	const obj = {};

	for (let i = 0; i < headers.length; i++) {
		const header = headers[i];
		const value = row[i];
		obj[header] = value;
	}

	return obj;
};

export const objectFieldToNumber = (obj, field) => {
	const parsedNumber = parseFloat(obj[field]);

	if (isNaN(parsedNumber)) {
		delete obj[field];
	} else {
		obj[field] = parsedNumber;
	}
};

// Removes multiple spaces and replaces them with a single space
export const removeMultipleSpaces = (s) => s.replace(/ {2,}/g, ' ');

export const toIso8601DateString = (gameDateUtc, utcTime) => `${gameDateUtc}T${utcTime}:00.000Z`;
