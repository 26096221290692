import $ from 'jquery';
import _ from 'lodash';
import moment from 'moment';

import { isNil } from '../helpers.js';

export function init() {
	if ($('#ad-edit-section').length === 0) {
		return;
	}

	broadside.diff = {};

	// Context menu
	//
	$('#save-ad').on('click', onSaveAdClick);
	$('#delete-ad').on('click', onDeleteAdClick);

	// Banner image
	//
	const src = $('#preview-image').attr('src');

	if (_.isEmpty(src)) {
		$('#header-image-container').hide();
	}

	$('#input-file').on('change', function () {
		const file = $(this)[0].files[0];
		const reader = new FileReader();

		reader.onload = (e) => $('#preview-image').attr('src', e.target.result);
		reader.readAsDataURL(file);

		$('#header-image-container').show();
	});

	// Boolean inputs
	//
	_.each(['isHidden'], (prop) => {
		const $elem = $(`#${prop}`);
		$elem.on('input', () => {
			$(`#${prop} .empty-option`).remove();
			_.set(broadside, `diff.${prop}`, parseInt($elem.val(), 10) === 1);
		});
	});

	// Text inputs
	//
	_.each(['title', 'url'], (prop) => {
		const $elem = $(`#${prop}`);
		$elem.on('input', () => {
			_.set(broadside, `diff.${prop}`, $elem.val());
		});
	});

	// Date inputs
	//
	_.each(['endDate', 'startDate'], (prop) => {
		const $elem = $(`#${prop}`);
		$elem.on('input', () => {
			const value = $elem.val();
			const utcDate = _.isEmpty(value) ? null : moment(value).utc().toISOString();
			_.set(broadside.diff, prop, utcDate);
		});
	});
}

async function onDeleteAdClick() {
	if (!confirm('Are you sure you want to delete this ad?')) {
		return;
	}

	const adId = _.get(broadside, 'ad.id');
	const path = `/api/4.0/ads/${adId}`;
	const response = await fetch(path, {
		method: 'DELETE',
	});

	if (response.status !== 200) {
		console.log(response);
		return;
	}

	location.href = '/ads';
}

async function onSaveAdClick() {
	const adId = _.get(broadside, 'ad.id');
	const data = new FormData();

	_.each(broadside.diff, (value, key) => {
		data.append(key, value);
	});

	const file = $('#input-file')[0].files[0];
	if (!isNil(file)) {
		data.append('file', file);
	}

	const path = isNil(adId) ? '/api/4.0/ads' : `/api/4.0/ads/${adId}`;
	const response = await fetch(path, {
		body: data,
		method: 'POST',
	});

	if (response.status !== 200) {
		console.log(response);
		return;
	}

	location.href = '/ads';
}
