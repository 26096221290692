import $ from 'jquery';
import _ from 'lodash';

export function init() {
	if ($('#team-section').length === 0) {
		return;
	}

	$('#delete-team').on('click', onDeleteTeamClick);
	$('#join-team, #join-team-large').on(
		'click',
		async () => await onJoinOrLeaveTeamClick({ join: true })
	);
	$('#leave-team').on('click', async () => await onJoinOrLeaveTeamClick({ join: false }));
}

async function onDeleteTeamClick() {
	if (!confirm('Are you sure you want to delete this group?')) {
		return;
	}

	const teamId = _.get(broadside, 'team.id');
	const path = `/api/4.0/teams/${teamId}`;
	const response = await fetch(path, {
		method: 'DELETE',
	});

	if (response.status !== 200) {
		console.log(response);
		return;
	}

	location.href = '/teams';
}

async function onJoinOrLeaveTeamClick({ join }) {
	const isAuthenticated = _.get(broadside, 'isAuthenticated', false);

	if (!isAuthenticated) {
		location.href = '/sign-in';
		return;
	}

	const teamId = _.get(broadside, 'team.id');
	const command = join ? 'join' : 'leave';
	const path = `/api/4.0/teams/${teamId}/${command}`;
	const response = await fetch(path, {
		headers: { 'Content-Type': 'application/json' },
		method: 'POST',
	});

	if (response.status !== 200) {
		console.log(response);
		return;
	}

	location.href = `/teams/${teamId}`;
}
