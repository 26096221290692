import $ from 'jquery';
import _ from 'lodash';

import { DOUBLE_DEFAULT_MARGIN } from '../constants.js';

export function init() {
	if ($('#team-messages-section').length === 0) {
		return;
	}

	broadside.diff = {};

	// Text inputs
	//
	_.each(['message'], (prop) => {
		const $elem = $(`#${prop}`);
		$elem.on('input', function () {
			_.set(broadside.diff, prop, $elem.val());

			// Auto-size
			//
			const $textarea = $(this);
			setTimeout(() => {
				$textarea.css('height', 'auto');
				const scrollHeight = $textarea.prop('scrollHeight');
				$textarea.height(scrollHeight - DOUBLE_DEFAULT_MARGIN);
			}, 0);
		});
	});

	$('.post-message').on('click', onPostMessageClick);
}

async function onPostMessageClick() {
	const text = _.get(broadside, 'diff.message', '').trim();

	if (_.isEmpty(text)) {
		$('#message').select();
		return;
	}

	const teamId = _.get(broadside, 'team.id');
	const path = `/api/4.0/messages/teams/${teamId}`;
	const response = await fetch(path, {
		body: JSON.stringify(broadside.diff),
		headers: { 'Content-Type': 'application/json' },
		method: 'POST',
	});

	if (response.status !== 200) {
		console.log(response);
		return;
	}

	location.href = `/teams/${teamId}/messages`;
}
