import $ from 'jquery';
import _ from 'lodash';

export function init() {
	$('#emails-from-players').on('change', async () => await onToggleEmailsFromPlayers());
}

async function onToggleEmailsFromPlayers() {
	const isChecked = $('#emails-from-players').is(':checked');
	await toggleEmailsNotificationsFromPlayers(isChecked);
}

async function toggleEmailsNotificationsFromPlayers(isEnabled) {
	const response = await fetch('/api/4.0/player/', {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify({
			emailNotifications: isEnabled,
		}),
	});

	if (response.status !== 200) {
		console.log(response);
		return;
	}
}
