import $ from 'jquery';
import _ from 'lodash';
import moment from 'moment-timezone';

import {
	DATE_FORMAT,
	DATE_FORMAT_INPUT,
	DATE_TIME_FORMAT_INPUT,
	DOUBLE_DEFAULT_MARGIN,
	INNER_CONTAINER_WIDTH,
} from '../constants.js';
import { isNil } from '../helpers.js';

export function adjustUtcDatesToLocal() {
	$('[data-utc-date]').each(function () {
		const $elem = $(this);
		const utcDate = $elem.attr('data-utc-date');

		if (!_.isEmpty(utcDate)) {
			const dateFormat = $elem.attr('data-date-format');
			const timezone = $elem.attr('data-timezone');
			const isInputDate = $elem.prop('tagName') === 'INPUT' && $elem.attr('type') === 'date';
			const isInputDateTimeLocal =
				$elem.prop('tagName') === 'INPUT' && $elem.attr('type') === 'datetime-local';

			if (isInputDate) {
				const value = moment
					.utc(utcDate)
					.local()
					.format(dateFormat || DATE_FORMAT_INPUT);

				$elem.val(value);
			} else if (isInputDateTimeLocal) {
				const value = moment
					.utc(utcDate)
					.local()
					.format(dateFormat || DATE_TIME_FORMAT_INPUT);

				$elem.val(value);
			} else if (dateFormat === 'from-now') {
				const value = moment.utc(utcDate).local().fromNow();
				$elem.text(value);
			} else if (timezone) {
				const value = moment
					.utc(utcDate)
					.tz(timezone)
					.format(dateFormat || DATE_FORMAT);
				$elem.text(value);
			} else {
				const value = moment
					.utc(utcDate)
					.local()
					.format(dateFormat || DATE_FORMAT);
				$elem.text(value);
			}
		}
	});
}

export function isEmptyOrWhitespace(s) {
	return !s || s.trim() === '';
}

export function positionControls() {
	positionContextMenu();
}

function positionContextMenu() {
	const rect = $('.inner-container').offset();

	if (isNil(rect)) {
		return;
	}

	const left = rect.left + INNER_CONTAINER_WIDTH + DOUBLE_DEFAULT_MARGIN;
	const isDesktop = $('.context-menu').css('position') === 'fixed';

	if (isDesktop) {
		$('.context-menu').css({ left }).show();
	} else {
		$('.context-menu').hide();
	}
}
