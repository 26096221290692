import $ from 'jquery';
import _ from 'lodash';

export function init() {
	if ($('#news-item-section').length === 0) {
		return;
	}

	// Context menu
	//
	$('#delete-news-item').on('click', onDeleteNewsItemClick);
	$('#push-news-item').on('click', onPushNewsItemClick);

	incrementNewsItemViewCount();
}

async function onDeleteNewsItemClick() {
	if (!confirm('Are you sure you want to delete this news item?')) {
		return;
	}

	await hideNewsItem();
}

async function onPushNewsItemClick() {
	const pushedDate = _.get(broadside, 'newsItem.pushedDate');
	const message = pushedDate
		? 'This news item has already been pushed. Send again?'
		: 'Are you sure you want to push this news item?';

	if (!confirm(message)) {
		return;
	}

	await pushNewsItem();
}

async function hideNewsItem() {
	const newsItemId = _.get(broadside, 'newsItem.id');
	const path = `/api/4.0/news/${newsItemId}`;
	const response = await fetch(path, {
		body: JSON.stringify({ isHidden: true }),
		headers: { 'Content-Type': 'application/json' },
		method: 'POST',
	});

	if (response.status !== 200) {
		console.log(response);
		return;
	}

	location.href = '/news';
}

async function incrementNewsItemViewCount() {
	const newsItemId = _.get(broadside, 'newsItem.id');
	const path = `/api/4.0/news/${newsItemId}/view-count`;
	const response = await fetch(path, {
		headers: { 'Content-Type': 'application/json' },
		method: 'POST',
	});

	if (response.status !== 200) {
		console.log(response);
	}
}

async function pushNewsItem() {
	const newsItemId = _.get(broadside, 'newsItem.id');
	const path = `/api/4.0/news/${newsItemId}/push`;
	const response = await fetch(path, {
		headers: { 'Content-Type': 'application/json' },
		method: 'POST',
	});

	if (response.status !== 200) {
		console.log(response);
		return;
	}

	alert('The push has been sent.');
}
