import $ from 'jquery';
import _ from 'lodash';

export function init() {
	if ($('#player-edit-section').length === 0) {
		return;
	}

	broadside.diff = {};

	// Text inputs
	//
	_.each(
		['alias', 'bio', 'email', 'favoritePlayers', 'favoriteTeams', 'occupation', 'name', 'website'],
		(prop) => {
			const $elem = $(`#${prop}`);
			$elem.on('input', () => {
				_.set(broadside.diff, prop, $elem.val());
			});
		}
	);

	// Numeric inputs
	//
	_.each(['age'], (prop) => {
		const $elem = $(`#${prop}`);
		$elem.on('input', () => {
			_.set(broadside.diff, prop, parseInt($elem.val()), 10);
		});
	});

	// Context menu
	//
	$('#save-player').on('click', onSavePlayerClick);
}

async function onSavePlayerClick() {
	await savePlayer();
}

async function savePlayer() {
	const response = await fetch('/api/4.0/player', {
		body: JSON.stringify(broadside.diff),
		headers: { 'Content-Type': 'application/json' },
		method: 'POST',
	});

	if (response.status !== 200) {
		console.log(response);
		return;
	}

	location.href = '/player';
}
