import $ from 'jquery';
import _ from 'lodash';

export function init() {
	$('#delete-account').on('click', onDeleteAccountClick);
}

async function onDeleteAccountClick() {
	if (!confirm('Are you sure you want to delete your account and all associated data?')) {
		return;
	}

	await deleteAccount();
}

async function deleteAccount() {
	const response = await fetch('/api/4.0/player/', {
		method: 'DELETE',
	});

	if (response.status !== 200) {
		console.log(response);
		return;
	}

	location.href = '/';
}
