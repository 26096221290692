import $ from 'jquery';
import _ from 'lodash';

import { getResultString } from '../helpers.js';

export function init() {
	if ($('#forgot-password-section').length === 0) {
		return;
	}

	$('#send-reset-link').on('click', onSendResetLinkClick);
}

async function onSendResetLinkClick() {
	const email = $('#email').val().trim();

	if (_.isEmpty(email)) {
		$('#email').select();
		return;
	}

	const path = `/api/4.0/send-reset-password-email`;
	const response = await fetch(path, {
		body: JSON.stringify({ email }),
		headers: { 'Content-Type': 'application/json' },
		method: 'POST',
	});

	const text = await response.text();
	const { result } = JSON.parse(text);
	const resultString = getResultString(result);

	if (response.status !== 200) {
		$('#error-block').show();
		$('#error-block .content-cell').text(resultString);
		return;
	}

	alert('A password reset link has been sent. Please check your email.');
	location.href = '/';
}
