import _ from 'lodash';
import moment from 'moment-timezone';

import { LOCAL_STORAGE_KEYS, NICE_DATE_FORMAT, SAN_FRANSISCO_COORDINATES } from '../constants.js';

const DEFAULT_ZOOM = 12;
const MAP_BASEMAP = 'streets-navigation-vector';
const MAP_CONTAINER_ID = 'map-view';
const MIN_ZOOM = 10;
const VIEW_TEAM_BTN_TITLE = 'View Team';
const VIEW_TEAM_BTN_ID = 'view-team';
const VIEW_GAME_BTN_TITLE = 'View Game';
const VIEW_GAME_BTN_ID = 'view-game';

export async function init() {
	let [latitude, longitude] = SAN_FRANSISCO_COORDINATES;
	let zoomLevel = DEFAULT_ZOOM;

	if (!_.isNil(getMapCenter()) && !_.isNil(getMapZoomLevel)) {
		// Retrieve previous map location and zoom level from local storage
		//
		const center = getMapCenter();
		latitude = center.latitude;
		longitude = center.longitude;

		zoomLevel = getMapZoomLevel();
	} else {
		initMap(latitude, longitude, zoomLevel);

		try {
			const position = await getPosition({
				enableHighAccuracy: true,
				timeout: 5000,
				maximumAge: 0,
			});
			latitude = position.coords.latitude;
			longitude = position.coords.longitude;
		} catch (err) {
			console.error(err.message);
		}
	}

	initMap(latitude, longitude, zoomLevel);
}

async function initMap(latitude, longitude, zoomLevel) {
	require([
		'esri/Map',
		'esri/views/MapView',
		'esri/Graphic',
		'esri/support/actions/ActionButton',
		'esri/popup/content/CustomContent',
		'esri/geometry/Point',
		'esri/core/watchUtils',
	], function (Map, MapView, Graphic, ActionButton, CustomContent, Point, watchUtils) {
		const map = new Map({
			basemap: MAP_BASEMAP,
		});

		const view = new MapView({
			map: map,
			center: [longitude, latitude], // Longitude, latitude
			constraints: {
				rotationEnabled: false,
			},
			container: MAP_CONTAINER_ID, // Element ID to render map
			zoom: zoomLevel,
		});

		view.when(() => {
			view.popup.on('trigger-action', async (event) => {
				// Set up click handlers for when user clicks 'View Team' or 'View Game'
				//
				if (event.action.id === VIEW_TEAM_BTN_ID) {
					const id = view.popup.selectedFeature.attributes.id;
					openNewTab('/teams/' + id);
				}
				if (event.action.id === VIEW_GAME_BTN_ID) {
					const id = view.popup.selectedFeature.attributes.id;
					openNewTab('/games/' + id);
				}
			});

			setUpOnMapMoveHandler();
		});

		function setUpOnMapMoveHandler() {
			watchUtils.whenTrue(view, 'stationary', async function () {
				const { latitude, longitude } = view.center;

				// Save map state to local storage
				//
				setMapCenter(latitude, longitude);
				setMapZoomlevel(view.zoom);

				// Fetch games and teams and render on map whenever map moves
				//
				if (view.zoom >= MIN_ZOOM) {
					const { topLeft, bottomRight } = getExtent(view);
					const { games, teams } = await query(topLeft, bottomRight);

					const gameGraphics = createGameGraphics(games);
					const teamGraphics = createTeamGraphics(teams);

					view.graphics.removeAll();
					view.graphics.addMany(gameGraphics);
					view.graphics.addMany(teamGraphics);
				}
			});
		}

		// Takes a point and returns two points to the top left and bottom right.
		//
		function getExtent(view) {
			const topLeftPoint = new Point({
				x: view.extent.xmin,
				y: view.extent.ymax,
				spatialReference: view.extent.spatialReference,
			});

			const bottomRightPoint = new Point({
				x: view.extent.xmax,
				y: view.extent.ymin,
				spatialReference: view.extent.spatialReference,
			});

			const topLeftCoordinates = [topLeftPoint.latitude, topLeftPoint.longitude];
			const bottomRightCoordinates = [bottomRightPoint.latitude, bottomRightPoint.longitude];

			return { topLeft: topLeftCoordinates, bottomRight: bottomRightCoordinates };
		}

		function createTeamGraphics(teams) {
			const graphics = [];

			for (const team of teams) {
				const {
					location: { latitude, longitude },
				} = team;

				const { point, symbol } = createPoint(latitude, longitude, 'green');

				const customContent = new CustomContent({
					outFields: ['*'],
					creator: () => {
						const label = team.players === 1 ? 'player' : 'players';
						return `<div>${team.players} ${label}</div>`;
					},
				});

				const viewTeamAction = new ActionButton({
					title: VIEW_TEAM_BTN_TITLE,
					id: VIEW_TEAM_BTN_ID,
				});

				const teamGraphic = new Graphic({
					geometry: point,
					symbol,
					popupTemplate: {
						title: '{name}',
						outFields: ['*'],
						content: [customContent],
						actions: [viewTeamAction],
					},
					attributes: { ...team },
				});

				graphics.push(teamGraphic);
			}

			return graphics;
		}

		function createGameGraphics(games) {
			const graphics = [];

			for (const game of games) {
				const {
					location: { latitude, longitude, venue },
				} = game;

				const { point, symbol } = createPoint(latitude, longitude, 'red');

				const viewGameAction = new ActionButton({
					title: VIEW_GAME_BTN_TITLE,
					id: VIEW_GAME_BTN_ID,
				});

				const time = moment.utc(game.startDate).local().format(NICE_DATE_FORMAT);
				const customContent = new CustomContent({
					outFields: ['*'],
					creator: () => {
						return `
						<div>
							<h5>${game.team.name}</h5>
							<p>Plays on ${time} (local)</p>
						</div>`;
					},
				});

				const gameGraphic = new Graphic({
					geometry: point,
					symbol,
					popupTemplate: {
						title: venue,
						outFields: ['*'],
						content: [customContent],
						actions: [viewGameAction],
					},
					attributes: { ...game },
				});

				graphics.push(gameGraphic);
			}

			return graphics;
		}

		function createPoint(latitude, longitude, color) {
			const point = {
				type: 'point',
				latitude,
				longitude,
			};

			const symbol = {
				type: 'picture-marker',
				url: `images/pushpin-${color}.png`,
				width: '32px',
				height: '32px',
				yOffset: '16px',
			};

			return { point, symbol };
		}
	});
}

async function query(topLeft, bottomRight) {
	const point0 = `${topLeft.join(',')}`;
	const point1 = `${bottomRight.join(',')}`;

	const api = `/api/4.0/search-region?point0=${point0}&point1=${point1}`;

	const response = await fetch(api, {
		headers: {
			'Content-Type': 'application/json',
		},
	});

	const text = await response.text();
	const result = JSON.parse(text);

	return result;
}

function getPosition(options) {
	return new Promise((resolve, reject) =>
		navigator.geolocation.getCurrentPosition(resolve, reject, options)
	);
}

function setMapCenter(latitude, longitude) {
	const center = { latitude, longitude };

	localStorage.setItem(LOCAL_STORAGE_KEYS.MAP_CENTER, JSON.stringify(center));
}

function setMapZoomlevel(zoomLevel) {
	localStorage.setItem(LOCAL_STORAGE_KEYS.MAP_ZOOM, JSON.stringify(zoomLevel));
}

function getMapCenter() {
	return getLocalStorage(LOCAL_STORAGE_KEYS.MAP_CENTER);
}

function getMapZoomLevel() {
	return getLocalStorage(LOCAL_STORAGE_KEYS.MAP_ZOOM);
}

function getLocalStorage(key) {
	const item = JSON.parse(localStorage.getItem(key));

	return item;
}

function openNewTab(link) {
	window.open(link, '_blank');
}
